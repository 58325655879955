<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('suppForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="suppForm" :model="suppForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="供应商编号" prop="supp_no">
            <el-input v-model="suppForm.supp_no" maxlength="10" @input="suppForm.supp_no = (helper.pureEC(suppForm.supp_no)).toUpperCase()" show-word-limit placeholder="请填写供应商编号">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商全称" prop="supp_name">
            <el-input v-model="suppForm.supp_name" maxlength="30" show-word-limit placeholder="请填写供应商全称" >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_abbr">
            <el-input v-model="suppForm.supp_abbr" maxlength="10" show-word-limit placeholder="请填写供应商简称" >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="供应商类型" prop="supp_type">
            <el-select v-model="suppForm.supp_type" placeholder="请选择供应商类型" size="mini" show-word-limit>
              <el-option v-for="item in suppType" :key="item.id" :label="item.label" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="联系人姓名" prop="supp_contact">
            <el-input v-model="suppForm.supp_contact" maxlength="20" show-word-limit placeholder="请填写联系人姓名" >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="联系电话" prop="supp_phone">
            <el-input v-model="suppForm.supp_phone"
              @input="suppForm.supp_phone = helper.pureNumber(suppForm.supp_phone)" maxlength="50" show-word-limit
            placeholder="请填写联系电话"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="主营类目" prop="supp_maincate">
            <el-select v-model="suppForm.supp_maincate" placeholder="请选择主营类目" size="mini" clearable show-word-limit>
              <el-option
                  v-for="item in mainBusinessList"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> 
        <el-col :md="8">
          <el-form-item label="采购经办人" prop="purc_stff_id">
            <el-select filterable v-model="suppForm.purc_stff_id" placeholder="请选择采购经办人" size="mini" clearable show-word-limit>
              <el-option
                  v-for="item in stffList"
                  :key="item.stff_id"
                  :label="item.stff_name"
                  :value="item.stff_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> 
        <el-col :md="8">
          <el-form-item label="供应商地址" prop="supp_addr">
            <el-input type="textarea" autosize v-model.trim="suppForm.supp_addr" maxlength="50" show-word-limit placeholder="请填写供应商简称">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { suppAPI } from "@api/modules/supp";
import { optnAPI } from "@api/modules/optn";
import { stffAPI } from "@/api/modules/staff";
import helper from "@assets/js/helper";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";

export default {
  name: "SuppAddMain",
  components: {
    addHeader,
    inputUser
  },
  data() {
    return {
      rules: {
        supp_no: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        supp_name: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        supp_type: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        supp_abbr: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
      },
      suppForm: {
        supp_no: null,  //供应商 编号 
        supp_type: null,  //供应商 类型 
        supp_abbr: null,  //供应商 简称
        supp_name: null,  // 供应商 全称
        supp_addr: null,  //供应商 地址 
        supp_contact: null,//联系人 姓名 
        supp_phone: null, //联系人 电话 
        supp_maincate: null, //主营类目
        purc_stff_id: null, 
      },
      suppType: [
        { id: 1, label: '加工厂' },
        { id: 2, label: '面料厂' },
        { id: 3, label: '配套厂' },
        { id: 4, label: '辅料厂' },
      ],
      stffList:[],
      mainBusinessList:[]
    }
  },
  watch: {
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.getCompType()
      this.getStffUser()
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            this.$message.warning("请把必填内容补充完整");
            return false;
          }
        });
      }).catch(() => { })
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData()
      this.suppForm = Object.assign(this.suppForm, staffForm)
      console.log(this.suppForm);
      post(suppAPI.addSupp, this.suppForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            })

            // this.jump(`/supp_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
            const permId = this.$route.query.perm_id
            this.jump('/supp_edit', {
              key: this.$UrlEncode.encode(JSON.stringify({
                perm_id: permId,
                form_id: res.data.data.form_id
              }))
            })

            this.resetForm('suppForm')
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '供应商编号重复'
            })
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData()
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('suppForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(() => { })
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getOptnByPermId, { perm_id: 10022 })
        .then((res) => {
          if (res.data.code === 0) {
            this.mainBusinessList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1).then(res => {
        if (res.data.code === 0) {
          this.stffList = res.data.data
          return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
</style>